import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { DEVICE_EVENT, TRANSPORT_EVENT, UI_EVENT, DEVICE } from 'trezor-connect';

@Component({
  selector: 'app-hello',
  templateUrl: './hello.component.html',
  styleUrls: ['./hello.component.css']
})
export class HelloComponent implements OnInit {
  helloCard: HTMLElement
  helloStatus: HTMLElement
  helloStatusNote: HTMLElement
  helloPair: HTMLElement

  constructor(private vault: VaultService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let debugMode = this.vault.isDebugMode();

    this.vault.subscribe(DEVICE_EVENT, (event) => {
      if (event.type === DEVICE.CONNECT) {
        this.clearDOM();
        setTimeout(() => {
          this.router.navigate(
            ['setup'],
            { queryParams: this.route.snapshot.queryParams }).then(() => this.vault.unsubscribeAll());
        }, 1000);
      }
    });

    this.vault.subscribe(TRANSPORT_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.vault.subscribe(UI_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.helloCard = document.getElementById('hello-card');
    this.helloStatus = document.getElementById('hello-status-text');
    this.helloStatusNote = document.getElementById('hello-status-note-text');
    this.helloPair = document.getElementById('hello-pair');

    this.clearDOM();

     if (/(android)/i.test(navigator.userAgent)) location.href = 'https://play.google.com/store/apps/details?id=com.hodllabs.wallet&hl=en';

    this.helloStatus.innerHTML = `
      Initializing wallet software &nbsp; <i class="fa-solid fa-spin fa-circle-notch"></i>
    `;

    if (this.vault.isInitialized()) {
      this.performChecks();
    } else {
      this.vault.init(null).then(() => {
        setTimeout(() => {
          this.vault.setInitialized();
          this.performChecks();
        }, 500);
      });
    }
  }
  
  refresh() {
    window.location.reload();
  }

  performChecks() {
    if (!this.vault.isInitialized()) {
      return;
    }

    this.helloStatus.innerText = `Let's begin by pairing your Vault E!`;
    this.helloStatusNote.innerText = `TIP: Reconnect your device if it appears stuck.`
    this.helloCard.style.paddingBottom = '25px';
    this.helloPair.style.display = 'inline-block';
    this.vault.renderWebUSBButton();
  }

  clearDOM() {
    this.helloCard.style.paddingBottom = '0';
    this.helloPair.style.display = 'none';
  }
}