import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelloComponent } from './wallet/hello/hello.component';
import { SetupComponent } from './wallet/setup/setup.component';
import { PortfolioComponent } from './wallet/portfolio/portfolio.component';
import { ToolsComponent } from './wallet/tools/tools.component';
import { ResetComponent } from './wallet/reset/reset.component';


const routes: Routes = [
  { path: 'hello', component: HelloComponent },
  { path: 'setup', component: SetupComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'tools', component: ToolsComponent },
  { path: 'reset', component: ResetComponent},
  { path: '', redirectTo: 'hello', pathMatch: 'full' },
  { path: '**', redirectTo: 'hello' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
