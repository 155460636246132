import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { DEVICE_EVENT, TRANSPORT_EVENT, UI_EVENT, UI } from 'trezor-connect';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Transaction as EthereumTX, TxOptions } from '@ethereumjs/tx/dist.browser';
import Common from '@ethereumjs/common/dist';
import { getDefaultModalConfig } from 'src/app/common/functions';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})

export class PortfolioComponent implements OnInit {
  @ViewChild('pinEntry', { static: false })
  private pinEntry: TemplateRef<any>;
  @ViewChild('confirmOnDevice', { static: false })
  private confirmOnDevice: TemplateRef<any>;
  @ViewChild('bootloader', { static: false })
  private bootloader: TemplateRef<any>;
  @ViewChild('passphraseEntry', { static: false })
  private passphraseEntry: TemplateRef<any>;
  @ViewChild('feedbackModal', { static: false })
  private feedbackModal: TemplateRef<any>;
  @ViewChild('deviceResetWarning', { static: false })
  private deviceResetWarning: TemplateRef<any>;
  @ViewChild('insufficientFundWarning', { static: false })
  private insufficientFundWarning: TemplateRef<any>;

  // purpose / cointype / account / change / address
  private btcPath = "m/84'/0'/0'/0/0"; // Segwit (BIP-84) / bitcoin mainnet / first bitcoin master account / receive (not change) / first address
  private ethPath = "m/44'/60'/0'/0/0";
  private btcCoin = 'btc';
  private ethCoin = 'eth';

  public device = undefined;
  public insufficientFunds = false;

  public btcAddress = undefined;
  public btcBalance = undefined;
  public btcPendingBalance = undefined;
  public btcTxns = [];
  public btcAccountUTXOs = [];
  public btcAccountAddresses = { used: [], unused: [], change: [] };
  public btcWorkingTxn = { inputs: [], outputs: [] };

  public ethAddress = undefined;
  public ethBalance = undefined;
  public ethPendingBalance = undefined;
  public ethTxns = [];
  public ethNonce = undefined;

  public btcPrice = undefined;
  public btcMarketCap = undefined;
  public ethPrice = undefined;
  public ethMarketCap = undefined;

  public deviceName = new FormControl('');
  public sendAddress = new FormControl('');
  public sendAmount = new FormControl('');
  public sendFee = new FormControl('');
  public fwVersion = new FormControl('');
  public pin: string = '';
  public passphrase = new FormControl('');
  public feedback = new FormControl('');
  public feedbackReplyTo = new FormControl('');
  public purchaseAmount = new FormControl('');
  public includeFees = new FormControl('');

  public isPortfolio: boolean = true;
  public isBitcoin: boolean = true;
  public isEthereum: boolean = false;
  public isTransactions: boolean = false;
  public isSend: boolean = false;
  public isSettings: boolean = false;
  public canSendBTC: boolean = false;
  public canSendETH: boolean = false;

  public weiInEth = 1000000000000000000;
  public gweiInEth = 1000000000;
  public satsInBtc = 100000000;

  public ethGasPrice = 0;
  public ethGasLimit = 0;
  public ethFee = 0;
  public ethFeeConverted = 0;
  public ethTotal = 0;
  public ethTotalConverted = 0;

  public btcFeePrice = 0;
  public btcFee = 0;
  public btcTotal = 0;

  public walletReady = false;

  public hasError = false;
  public currentError = '';

  public menuItemPortfolio: HTMLElement
  public menuItemTransactions: HTMLElement
  public menuItemSend: HTMLElement
  public menuItemSettings: HTMLElement

  public btcSelector: HTMLElement
  public ethSelector: HTMLElement

  public buyButton: HTMLElement

  public passphraseError: boolean;
  public wasCopied: boolean = false;
  public deviceNameChanged: boolean = false;

  public qaFlag: boolean;

  public widget: any;
  public reservationQuote: any;
  public reservationQuoteNetworkFees: any;
  private purchaseAmountForCurrentQuote: string;
  private includeFeesForCurrentQuote: boolean;
  public isQuatation: boolean = false;
  public isPassword: boolean = false;

  public disableQuote: boolean = true;
  public disableBuy: boolean = false;


  public selectedValue = localStorage.getItem('data-theme') ? localStorage.getItem('data-theme') : '';
  input: any;

  constructor(private vault: VaultService, private modal: NgbModal, private router: Router, private route: ActivatedRoute) { }

  async ngOnInit() {
    if (!this.vault.isInitialized()) this.router.navigate(
      ['hello'],
      { queryParams: this.route.snapshot.queryParams }).then(() => window.location.reload());

    this.qaFlag = this.route.snapshot.queryParamMap.get('qa') ? true : false;

    let debugMode = this.vault.isDebugMode();

    this.vault.subscribe(DEVICE_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.vault.subscribe(TRANSPORT_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.vault.subscribe(UI_EVENT, (event) => {
      if (debugMode) console.log(event);

      if (event.type === UI.REQUEST_PIN) {
        this.modal.open(this.pinEntry, { ...getDefaultModalConfig(), windowClass: 'modal-window pin-entry-modal' });
      }

      if (event.type === UI.REQUEST_BUTTON) {
        this.modal.open(this.confirmOnDevice, { ...getDefaultModalConfig(), windowClass: 'modal-window confirm-on-device-modal confirmOnDevice-body' });
      }

      if (event.type === UI.REQUEST_PASSPHRASE) {
        let passphrase = this.vault.getPassphrase();
        if (passphrase !== undefined) {
          this.vault.uiResponse({ type: UI.RECEIVE_PASSPHRASE, payload: { value: passphrase, save: true, passphraseOnDevice: false } });
        } else {
          this.modal.open(this.passphraseEntry, { ...getDefaultModalConfig(), windowClass: 'modal-window passphrase-entry-modal' });
        }
      }

      if (event.type === UI.CLOSE_UI_WINDOW) {
        this.modal.dismissAll();
      }

      if (event.type === UI.INSUFFICIENT_FUNDS) {
        this.insufficientFunds = true;
      }

      if (event.type === UI.SELECT_ACCOUNT) {
        if (event.payload.type === 'end') {
          this.vault.uiResponse({ type: UI.RECEIVE_ACCOUNT, payload: 0 });
        }
      }

      if (event.type === UI.SELECT_FEE) {
        this.vault.uiResponse({ type: UI.RECEIVE_FEE, payload: { type: 'send', value: 'normal' } })
      }
    });

    this.menuItemPortfolio = document.getElementById('menu-item-portfolio');
    this.menuItemTransactions = document.getElementById('menu-item-transactions');
    this.menuItemSend = document.getElementById('menu-item-send');
    this.menuItemSettings = document.getElementById('menu-item-settings');
    this.btcSelector = document.getElementById('btc-selector');
    this.ethSelector = document.getElementById('eth-selector');
    this.buyButton = document.getElementById('buy-button');
    this.device = this.vault.getCurrentDevice();

    await this.vault.ethereumGetAddress({ path: this.ethPath, showOnTrezor: false }).then(results => {
      if (results.success) {
        this.ethAddress = results.payload.address;
        this.getEthereumTxns();
      } else {
        console.error(results);
      }
    });

    await this.vault.bitcoinGetAddress({ path: this.btcPath, showOnTrezor: false }).then(results => {
      if (results.success) {
        this.btcAddress = results.payload.address;
        this.getBitcoinTxns();
      } else {
        console.error(results);
      }
    });

    await this.vault.bitcoinEstimateFees().then(async results => {
      this.btcFeePrice = Number(results.sats);
      this.checkWalletReady();
    });

    await this.vault.ethereumEstimateFees().then(results => {
      this.ethGasPrice = this.gweiToWei(results.gasPrice);
      this.ethGasLimit = results.gasLimit;
      this.ethFee = this.ethGasLimit * this.ethGasPrice;
      this.ethFeeConverted = this.weiToEth(this.ethFee);
      this.checkWalletReady();
    });

    this.updateCoinGeckoInfo();

    setInterval(() => {
      this.updateCoinGeckoInfo();
    }, 60000)

    this.includeFees.setValue(true);

    const script = document.createElement('script');
    script.src = 'https://verify.sendwyre.com/js/verify-module-init-beta.js';
    script.async = true;
    document.body.appendChild(script);
  }

  async toggleWyreButtons(e) {
    if (e.target.value < 10) {
      this.disableBuy = true;
      this.disableQuote = true;
      return;
    };

    if (e.target.value !== this.purchaseAmountForCurrentQuote && this.purchaseAmountForCurrentQuote !== undefined) {
      this.disableBuy = true;
    } else {
      this.disableBuy = false;
    }

    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) {
      this.disableQuote = false;
    } else {
      this.disableQuote = true;
    }
  }

  async toggleIncludeFees(e) {
    if (e.target.value !== this.includeFeesForCurrentQuote && this.includeFeesForCurrentQuote !== undefined) {
      this.disableBuy = true;
    } else {
      this.disableBuy = false;
    }
  }

  async getReservationQuote() {
    if (Number(this.purchaseAmount.value) < 10) {
      return;
    }

    this.includeFeesForCurrentQuote = this.includeFees.value;
    this.purchaseAmountForCurrentQuote = this.purchaseAmount.value.toString();
    this.disableBuy = false;

    const options = {
      'sourceAmount': this.purchaseAmount.value.toString(),
      'amountIncludeFees': this.includeFees.value,
      'sourceCurrency': 'USD',
      'destCurrency': this.isBitcoin ? 'BTC' : 'ETH',
      'lockFields': ['sourceAmount'],
      'paymentMethod': 'debit-card',
      'dest': this.isBitcoin ? `bitcoin:${this.btcAddress}` : `ethereum:${this.ethAddress}`,
      'country': 'US'
    };

    const reservation = await this.vault.getReservation(options);
    console.log(reservation);
    this.reservationQuote = await this.vault.getReservationQuote(reservation['reservation']);

    if (this.isBitcoin) {
      this.reservationQuoteNetworkFees = this.reservationQuote?.quote?.fees.BTC * this.btcPrice;
    } else {
      this.reservationQuoteNetworkFees = this.reservationQuote?.quote?.fees.ETH * this.ethPrice;
    }

    this.widget = new window['Wyre']({
      env: 'prod',
      reservation: reservation['reservation'],
      operation: {
          type: 'debitcard-hosted-dialog'
      }
    });

    this.widget.on("paymentSuccess", function (e) {
        this.isQuatation = false;
        this.purchaseAmount.setValue('');
    });

    this.isQuatation = true;
  }

  closeModal() {
    this.modal.dismissAll();
  }

  openFeedbackModal() {
    this.modal.dismissAll();
    this.modal.open(this.feedbackModal, { ...getDefaultModalConfig('sm', true), windowClass: 'modal-window feedback-form-modal' });
  }

  deviceResetWarningCancel() {
    this.modal.dismissAll();
    this.modal.open(this.pinEntry, { ...getDefaultModalConfig(), windowClass: 'modal-window' });
  }

  deviceResetWarningConfirm() {
    this.modal.dismissAll();
    this.router.navigate(['reset'], { queryParams: this.route.snapshot.queryParams });
  }

  sendFeedback() {
    this.vault.sendSupportEmail(String(this.feedbackReplyTo.value), String(this.feedback.value)).then(results => console.log(results)).catch(err => console.error(err));
    this.modal.dismissAll();
    this.feedbackReplyTo.setValue('');
    this.feedback.setValue('');
  }

  checkWalletReady() {
    if (this.ethAddress, this.btcAddress, this.ethPrice, this.btcPrice) {
      this.walletReady = true;
    }
  }

  async copyToClipboard() {
    await navigator.clipboard.writeText(this.isBitcoin ? this.btcAddress : this.ethAddress);
    this.wasCopied = true;
    setTimeout(() => {
      this.wasCopied = false;
    }, 3000);
  }

  updateCoinGeckoInfo() {
    this.vault.coinGeckoGetBitcoinInfo().then(results => {
      if (results && results.tickers && results.market_data && results.market_data.market_cap) {
        for (let i = 0; i <= results.tickers.length; i++) {
          if (results.tickers[i].target.toLowerCase() === 'usd') {
            this.btcPrice = results.tickers[i].last;
            break;
          }
        }

        this.btcMarketCap = results.market_data.market_cap['usd'];
      }
    }).catch(err => {
      console.error(err);
    });

    this.vault.coinGeckoGetEthereumInfo().then(results => {
      if (results && results.tickers && results.market_data && results.market_data.market_cap) {
        for (let i = 0; i <= results.tickers.length; i++) {
          if (results.tickers[i].target.toLowerCase() === 'usd') {
            this.ethPrice = results.tickers[i].last;
            break;
          }
        }

        this.ethMarketCap = results.market_data.market_cap['usd'];
      }
    });
  }

  // 1 ETH
  // = 1,000,000,000 GWEI
  // = 1,000,000,000,000,000,000 WEI

  weiToGwei(wei) {
    return wei / 1000000000;
  }

  weiToEth(wei) {
    return wei / 1000000000000000000;
  }

  gweiToWei(gwei) {
    return gwei * 1000000000;
  }

  gweiToEth(gwei) {
    return gwei / 1000000000;
  }

  ethToWei(eth) {
    return eth * 1000000000000000000;
  }

  ethToGwei(eth) {
    return eth * 1000000000;
  }

  addPinDigit(digit: string) {
    if (!this.pin) this.pin = '';

    this.pin += digit;
  }

  deletePinDigit() {
    this.pin = this.pin.slice(0, -1);
  }

  clearErrors() {
    this.currentError = '';
    this.hasError = false;
    this.canSendBTC = false;
    this.canSendETH = false;
  }

  async calculateBtcFees() {
    this.clearErrors();

    if (this.btcTotal >= this.btcBalance) {
      this.hasError = true;
      this.currentError = 'Insufficient funds!';
      this.modal.open(this.insufficientFundWarning, { ...getDefaultModalConfig(), windowClass: 'modal-window fund-warning-modal' });
      return;
    }

    this.vault.bitcoinComposeTransaction({
      coin: this.btcCoin,
      outputs: [{
        amount: String(Math.round(this.sendAmount.value * this.satsInBtc)),
        address: this.sendAddress.value
      }],
      account: {
        path: "m/84'/0'/0'",
        addresses: this.btcAccountAddresses,
        utxo: this.btcAccountUTXOs,
      },
      feeLevels: [
        { feePerUnit: Math.round(this.btcFeePrice).toString() }
      ]
    }).then(results => {
      if (results.success) {
        if (results.payload[0].error && results.payload[0].error.toLowerCase() === 'not-enough-funds') {
          this.hasError = true;
          this.currentError = 'Insufficient funds!';
        } else {
          this.btcFee = results.payload[0].fee / this.satsInBtc;
          this.btcTotal = this.btcFee + Number(this.sendAmount.value);
          this.btcWorkingTxn.inputs = results.payload[0].transaction.inputs;
          this.btcWorkingTxn.outputs = results.payload[0].transaction.outputs;
          this.canSendBTC = true;
        }
      } else {
        console.error('Failed to precompose txn!');
      }
    }).catch(err => {
      console.log(err);
    });
  }

  filterChangeAddresses(addresses) {
    let filtered = [];
    for (let i = 0; i < addresses.length; i++) {
      if (!this.btcAccountAddresses.change.includes(addresses[i])) {
        filtered.push(addresses[i]);
      }
    }

    return filtered;
  }

  calculateEthTotal() {
    this.clearErrors();
    this.ethTotal = this.ethFee + this.ethToWei(Number(this.sendAmount.value));
    this.ethTotalConverted = this.weiToEth(this.ethTotal);
    if (this.ethTotal >= this.ethBalance) {
      this.hasError = true;
      this.currentError = 'Insufficient funds!';
      this.modal.open(this.insufficientFundWarning, { ...getDefaultModalConfig(), windowClass: 'modal-window fund-warning-modal' });
      this.canSendETH = false;
    } else {
      this.canSendETH = true;
    }
  }

  changePin() {
    this.vault.changePin({}).then(results => console.log(results));
  }

  cleanState() {
    this.isPortfolio = false;
    this.isTransactions = false;
    this.isSend = false;
    this.isSettings = false;

    this.menuItemPortfolio.classList.remove('text-active');
    this.menuItemTransactions.classList.remove('text-active');
    this.menuItemSend.classList.remove('text-active');
    this.menuItemSettings.classList.remove('text-active');
  }

  async getBitcoinTxns() {
    if (!this.btcAddress) return;

    this.btcTxns = [];

    await this.vault.bitcoinGetAccountInfo({ coin: this.btcCoin, details: 'txs' }).then(async results => {
      if (results.success) {
        this.btcAccountAddresses = results.payload.addresses;
        this.btcAccountUTXOs = results.payload.utxo;
      }

      if (results.success) {
        let txns = {};
        let txnIds = [];

        if (results.payload.history.total > 0 && results.payload.history.hasOwnProperty('transactions')) {
          for (let i = 0; i < results.payload.history.transactions.length; i++) {
            txnIds.push(results.payload.history.transactions[i].txid);
            txns[results.payload.history.transactions[i].txid] = {
              amount: results.payload.history.transactions[i].amount,
              blockHash: results.payload.history.transactions[i].blockHash,
              blockHeight: results.payload.history.transactions[i].blockHeight,
              blockTime: results.payload.history.transactions[i].blockTime,
              fee: results.payload.history.transactions[i].fee,
              targets: results.payload.history.transactions[i].targets,
              type: results.payload.history.transactions[i].type
            };
          }
  
          await this.vault.blockchainGetTransactions({ coin: this.btcCoin, txs: txnIds }).then(results => {
            if (results.success) {
              let changeAddresses = [];
  
              for (let a = 0; a < this.btcAccountAddresses.change.length; a++) {
                changeAddresses.push(this.btcAccountAddresses.change[a].address);
              }
  
              for (let i = 0; i < results.payload.length; i++) {
                let vins = [];
                let vouts = [];
  
                for (let j = 0; j < results.payload[i].tx['vin'].length; j++) {
                  if (results.payload[i].tx['vin'][j]['isAddress']) {
                    for (let k = 0; k < results.payload[i].tx['vin'][j]['addresses'].length; k++) {
                      if (!changeAddresses.includes(results.payload[i].tx['vin'][j]['addresses'][k])) {
                        vins.push(results.payload[i].tx['vin'][j]['addresses'][k]);
                      }
                    }
                  }
                }
  
                for (let j = 0; j < results.payload[i].tx['vout'].length; j++) {
                  if (results.payload[i].tx['vout'][j]['isAddress']) {
                    for (let k = 0; k < results.payload[i].tx['vout'][j]['addresses'].length; k++) {
                      if (!changeAddresses.includes(results.payload[i].tx['vout'][j]['addresses'][k])) {
                        vouts.push(results.payload[i].tx['vout'][j]['addresses'][k]);
                      }
                    }
                  }
                }
  
                txns[results.payload[i].tx['txid']].vins = vins;
                txns[results.payload[i].tx['txid']].vouts = vouts;
              }
  
              for (let txn in txns) {
                this.btcTxns.push({ txid: txn, tx: txns[txn] });
              }
            }
          });
        }

        this.btcBalance = Number(results.payload.balance) / this.satsInBtc;
        this.btcPendingBalance = Number(results.payload.availableBalance) / this.satsInBtc;
      }
    });

    this.checkWalletReady();
  }

  fixedLengthBalance(length) {
    let balance = this.isBitcoin ? this.btcPendingBalance || 0 : this.weiToEth(this.ethBalance);

    return balance === 0 ? balance : balance.toFixed(length);
  }

  getEthereumTxns() {
    if (!this.ethAddress) return;

    this.ethTxns = [];

    this.vault.ethereumGetAccountInfo({ coin: this.ethCoin, descriptor: this.ethAddress, details: 'txs' }).then(async results => {
      if (results.success) {
        let txns = {};
        let txnIds = [];

        if (results.payload.history.total > 0 && results.payload.history.hasOwnProperty('transactions')) {
          for (let i = 0; i < results.payload.history.transactions.length; i++) {
            txnIds.push(results.payload.history.transactions[i].txid);
            txns[results.payload.history.transactions[i].txid] = {
              amount: results.payload.history.transactions[i].amount,
              blockHash: results.payload.history.transactions[i].blockHash,
              blockHeight: results.payload.history.transactions[i].blockHeight,
              blockTime: results.payload.history.transactions[i].blockTime,
              fee: results.payload.history.transactions[i].fee,
              targets: results.payload.history.transactions[i].targets,
              type: results.payload.history.transactions[i].type
            };
          }
  
          this.ethNonce = results.payload.misc.nonce;
  
          await this.vault.blockchainGetTransactions({ coin: this.ethCoin, txs: txnIds }).then(results => {
            if (results.success) {
              for (let i = 0; i < results.payload.length; i++) {
                let vins = [];
                let vouts = [];
  
                for (let j = 0; j < results.payload[i].tx['vin'].length; j++) {
                  if (results.payload[i].tx['vin'][j]['isAddress']) {
                    for (let k = 0; k < results.payload[i].tx['vin'][j]['addresses'].length; k++) {
                      vins.push(results.payload[i].tx['vin'][j]['addresses'][k]);
                    }
                  }
                }
  
                for (let j = 0; j < results.payload[i].tx['vout'].length; j++) {
                  if (results.payload[i].tx['vout'][j]['isAddress']) {
                    for (let k = 0; k < results.payload[i].tx['vout'][j]['addresses'].length; k++) {
                      vouts.push(results.payload[i].tx['vout'][j]['addresses'][k]);
                    }
                  }
                }
  
                txns[results.payload[i].tx['txid']].vins = vins;
                txns[results.payload[i].tx['txid']].vouts = vouts;
              }
  
              for (let txn in txns) {
                this.ethTxns.push({ txid: txn, tx: txns[txn] });
              }
            }
          });
        }

        this.ethBalance = Number(results.payload.balance);
        this.ethPendingBalance = Number(results.payload.availableBalance);
      }
    });

    this.checkWalletReady();
  }

  sendBitcoin() {
    this.clearErrors();
    this.vault.bitcoinSignTransaction({
      coin: this.btcCoin,
      inputs: this.btcWorkingTxn.inputs,
      outputs: this.btcWorkingTxn.outputs,
      push: false,
    }).then(results => {
      if (results.success) {
        this.vault.bitcoinPushTransaction({ coin: this.btcCoin, tx: results.payload['serializedTx'] }).then(results => {
          if (results.success) {
            this.sendAmount.setValue('');
            this.sendAddress.setValue('');
            this.btcFee = 0;
            this.btcTotal = 0;
            this.canSendBTC = false;
            this.getBitcoinTxns();
          } else {
            console.error('Failed to send txn!');
          }
        });
      } else {
        console.error('Failed to sign txn!');
      }
    }).catch(err => console.log(err));
  }

  sendEthereum() {
    this.clearErrors();
    console.log('Sending', this.ethTotal, 'wei to', String(this.sendAddress.value));

    let txn = {
      to: this.sendAddress.value,
      value: '0x' + (this.ethToWei(Number(this.sendAmount.value))).toString(16),
      chainId: 1,
      nonce: '0x' + Number(this.ethNonce).toString(16),
      gasLimit: '0x' + Number(this.ethGasLimit).toString(16),
      gasPrice: '0x' + Number(this.ethGasPrice).toString(16)
    };

    console.log(txn);

    this.vault.ethereumSignTransaction({
      path: this.ethPath,
      transaction: txn
    }).then(results => {
      if (results.success) {
        console.log('RSV:', results.payload.r, results.payload.s, results.payload.v);

        let rtxn = {
          ...txn,
          v: results.payload.v,
          r: results.payload.r,
          s: results.payload.s,
        };

        const options: TxOptions = { common: new Common({ chain: 'mainnet' }) };

        const signedTxn = EthereumTX.fromTxData(rtxn, options);
        console.log(signedTxn.common.chainName());
        console.log('SIGNED TXN:', signedTxn);
        const serializedTxn = signedTxn.serialize();
        console.log('SERIALIZED TXN:', '0x' + serializedTxn.toString('hex'));

        this.vault.ethereumPushTransaction({ coin: this.ethCoin, tx: '0x' + serializedTxn.toString('hex') }).then(results => {
          console.log(results);
          if (results.success) {
            this.sendAmount.setValue('');
            this.sendAddress.setValue('');
            this.ethTotal = 0;
            this.ethTotalConverted = 0;
            this.canSendETH = false;
            this.getEthereumTxns();
          } else {
            console.error('Failed to send txn!');
          }
        });
      } else {
        console.error('Failed to sign txn!');
      }
    });
  }

  sendPin() {
    this.vault.uiResponse({ type: UI.RECEIVE_PIN, payload: this.pin });
    this.modal.dismissAll();
    this.pin = '';
  }

  sendPassphrase() {
    if (this.passphrase.value.length < 10 || this.passphrase.value === this.passphrase.value.toLowerCase()) {
      this.passphraseError = true;
    } else {
      this.vault.setPassphrase(this.passphrase.value);
      this.vault.uiResponse({ type: UI.RECEIVE_PASSPHRASE, payload: { value: this.passphrase.value, save: true, passphraseOnDevice: false } });
      this.modal.dismissAll();
      this.passphrase.setValue('');
    }
  }

  switchNavView(navItem: string) {
    this.cleanState();
    if (navItem === 'portfolio') {
      this.isPortfolio = true;
      this.menuItemPortfolio.classList.add('text-active');
    } else if (navItem === 'transactions') {
      this.isTransactions = true;
      this.menuItemTransactions.classList.add('text-active');
    } else if (navItem === 'send') {
      this.isSend = true;
      this.menuItemSend.classList.add('text-active');
    } else if (navItem === 'settings') {
      this.isSettings = true;
      this.menuItemSettings.classList.add('text-active');
    }
  }

  toggleCoinPortfolio(coin: string) {
    this.clearErrors();
    this.reservationQuote = null;

    this.sendAddress.setValue('');
    this.sendAmount.setValue('');
    this.btcFee = 0; // For now we leave this.ethFee alone as it's set once and never changes
    this.btcTotal = 0;
    this.ethTotal = 0;
    this.ethTotalConverted = 0;

    this.isBitcoin = false;
    this.btcSelector.classList.remove('text-active');
    this.isEthereum = false;
    this.ethSelector.classList.remove('text-active');

    switch (coin) {
      case 'btc':
        this.isBitcoin = true;
        this.btcSelector.classList.remove('text-link');
        this.btcSelector.classList.add('text-active');
        break;
      case 'eth':
        this.isEthereum = true;
        this.ethSelector.classList.remove('text-link');
        this.ethSelector.classList.add('text-active');
        break;
      default:
        this.isBitcoin = true;
        this.btcSelector.classList.remove('text-link');
        this.btcSelector.classList.add('text-active');
        break;
    }
  }

  getFirmwareVersionString() {
    return `v${this.device.firmwareRelease.release.version[0]}.${this.device.firmwareRelease.release.version[1]}.${this.device.firmwareRelease.release.version[2]}`;
  }

  firmwareUpdateAvailable() {
    let fwVersionString = this.getFirmwareVersionString();
    if (fwVersionString !== 'v1.8.3' && fwVersionString !== 'v0.6.0') return true;

    return false;
  }

  updateDevice() {
    this.vault.applySettings({ label: String(this.deviceName.value) }).then(results => {
      this.device = this.vault.getCurrentDevice();
      this.deviceName.setValue('');
    });
    this.deviceNameChanged = !this.deviceNameChanged
  }

  updateFirmware() {
    this.modal.open(this.bootloader, getDefaultModalConfig());
  }

  wipeDevice() {
    this.vault.wipeDevice().then(results => {
      if (results.success) {
        window.location.reload();
      }
    });
  }

  wipeDeviceWarning() {
    this.modal.dismissAll();
    this.modal.open(this.deviceResetWarning, getDefaultModalConfig());
  }

  detectNameChange() {
    this.deviceNameChanged = !this.deviceNameChanged
    this.deviceNameChanged = this.device.label !== this.deviceName.value;
  }

  onItemChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    document.documentElement.setAttribute('data-theme', selectedValue);
    localStorage.setItem('data-theme', selectedValue)
    return this.selectedValue;
  }

  toggleQuatation() {
    this.isQuatation = !this.isQuatation
  }

  togglePassword() {
    this.isPassword = !this.isPassword
    this.input.type = this.isPassword ? 'text' : 'password';
  }
}

