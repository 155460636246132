<div id="setup-container">

    <!-- If we don't have firmware OR we have out of date firmware -->
    <div id="setup-install-firmware" class="card" *ngIf="installFirmwareScreen">
        <div *ngIf="device.firmware === 'none'">
            <div class="margin_bottom-modal">
                <span class="text-16-px text-first-font">This device does not yet have firmware installed.</span>
            </div>
            <div>
                <button (click)="installFirmware()">Install!</button>
            </div>
        </div>
        <div *ngIf="device.firmware !== 'none'">
            <div class="margin_bottom-modal">
                <span class="text-16-px text-first-font">This device has an old version of our firmware. Let's update to
                    continue.
                    ({{device.firmwareRelease.release.version[0]}}.{{device.firmwareRelease.release.version[1]}}.{{device.firmwareRelease.release.version[2]}}
                    -> latest)</span>
            </div>
            <div>
                <button (click)="installFirmware()">Install!</button>
            </div>
        </div>
    </div>

    <!-- Default screen for a device yet to be setup -->
    <div id="setup-default" class="card setup_default-card" *ngIf="defaultSetupScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">SETUP</span>
        </div>
        <div class="setup-device-section">
            <img class="w-75" src="../../../assets/images/device-grey.svg">
        </div>
        <div id="setup-default-buttons">
            <button class="button-orange-outline w-100 setup-new-wallet-button" (click)="setupNewWallet(0)">Setup New Wallet</button>
            <button class="button-grey-outline w-100" (click)="recoverWallet(0)">Recover Wallet</button>
        </div>
    </div>

    <!-- Terms of service -->
    <div id="setup-tos" *ngIf="newWalletSetupTermsOfServiceScreen">
        <app-tos class="margin-bottom"></app-tos>
        <div>
            <button class="button-orange-outline px-3" (click)="setupNewWallet(1)">Agree & Continue</button>
        </div>
    </div>

    <!-- Setting a passphrase -->
    <div id="setup-passphrase" class="card" *ngIf="newWalletSetupPassphraseScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Create a Passphrase</span>
        </div>
        <div id="setup-passphrase-info" class="modal-description-section modal-description-text">
            <span class="">The passphrase must meet the following requirements:</span>
            <ol class="my-3">
                <li>Minimum of 10 alphanumeric characters</li>
                <li>Minimum of 1 uppercase character</li>
            </ol>
            <span class="">
                <strong>Note:</strong> Your device will ask you to enable the passphrase near the end of the setup
                process. This is expected behavior.
            </span>
        </div>
        <div id="setup-passphrase-inputs">
            <input id="passphrase" class="setup-passphrase-input-field full-width" type="text" autocomplete="off" [formControl]="passphrase" (keyup.enter)="setupNewWallet(2)">
            <button class="button-orange-outline full-width" (click)="setupNewWallet(2)">Continue</button>
        </div>
        <div *ngIf="passphraseError" class="margin-top">
            <span class="text-fifth-color">Passphrase does not currently meet requirements.</span>
        </div>
    </div>

    <!-- Seed word creation -->
    <div id="setup-seed-words" class="card" *ngIf="newWalletSetupSeedScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Create a Backup with Seed Words</span>
        </div>
        <div class="modal-description-section">
            <ol class="modal-description-text">
                <li>Please have a pen and included backup card ready.</li>
                <li>As soon as you continue you will presented with 24 seed words on your device (one at a time). Write each of them down on the backup card as they are presented.</li>
            </ol>
        </div>
        <div class="full-width">
            <button class="button-orange-outline full-width" (click)="setupNewWallet(3)">Continue</button>
        </div>
    </div>

    <!-- Set a device name -->
    <div id="setup-device-name" class="card" *ngIf="newWalletSetupNameScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Name Your Vault E</span>
        </div>
        <div class="modal-description-section">
            <span class="modal-description-text">Your device was successfully backed up and now it is time to give it a
                name. This is a fun way to personalize your experience!</span>
        </div>
        <div id="setup-device-name-inputs">
            <input id="device-name" class="setup-passphrase-input-field full-width" type="text" [formControl]="deviceName" (keyup.enter)="setupNewWallet(4)" />
            <button class="button-orange-outline full-width" (click)="setupNewWallet(4)">Continue</button><br />
        </div>
    </div>

    <!-- Set a pin -->
    <div id="setup-pin" class="card" *ngIf="newWalletSetupPinScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Set a Device Pin</span>
        </div>
        <div class="modal-description-section pb-0">
            <video class="full-width" autoplay loop src="../../../assets/video//Set_up_PIN_Dark.mp4"></video>
        </div>
        <div class="modal-description-section">
            <span class="modal-description-text"><strong>Note:</strong> You will be asked to confirm your pin.</span>
        </div>
        <div class="full-width">
            <button class="button-orange-outline full-width" (click)="setupNewWallet(6)">Continue</button>
        </div>
    </div>

    <!-- Recovery Terms of Service -->
    <div id="setup-tos" *ngIf="walletRecoveryTermsOfServiceScreen">
        <app-tos class="margin-bottom"></app-tos>
        <div>
            <button class="button-orange-outline px-3" (click)="recoverWallet(1)">Agree & Continue</button>
        </div>
    </div>

    <!-- Recovery Setting a Passphrase -->
    <div id="setup-passphrase" class="card" *ngIf="walletRecoveryPassphraseScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Create a Passphrase</span>
        </div>
        <div id="setup-passphrase-info" class="modal-description-section modal-description-text">
            <span class="">The passphrase must meet the following requirements:</span>
            <ol class="my-3">
                <li>Minimum of 10 alphanumeric characters</li>
                <li>Minimum of 1 uppercase character</li>
                <li>If you are recovering from a non Vault E seed please create a new wallet from the previous step and transfer your funds instead.</li>
            </ol>
            <span class="">
                <strong>Note:</strong> Your device will ask you to enable the passphrase near the end of the setup
                process. This is expected behavior.
            </span>
        </div>
        <div class="" id="setup-passphrase-inputs">
            <input id="passphrase" class="setup-passphrase-input-field full-width" type="text" autocomplete="off" [formControl]="passphrase" (keyup.enter)="recoverWallet(2)">
            <button class="button-orange-outline full-width" (click)="recoverWallet(2)">Continue</button>
        </div>
        <div *ngIf="passphraseError" class="margin-top">
            <span class="text-fifth-color">Passphrase does not currently meet requirements.</span>
        </div>
    </div>

    <!-- Recovery seed entry -->
    <div id="setup-seed-entry" class="card" *ngIf="walletRecoverySeedScreen">
        <div class="modal-heading-section border-bottom full-width text-center">
            <span class="modal-heading-text text-first-font">Seed Word Entry</span>
        </div>
        <div class="modal-description-section">
            <span class="modal-description-text">In order to recover your Vault E, please have you seed card ready. You will be asked to enter the 24
                seed words in a randomized order. Please follow the device screen for instruction.</span>
        </div>
        <div class="full-width">
            <button class="button-orange-outline full-width" (click)="recoverWallet(3)">Continue</button>
        </div>
    </div>
</div>

<ng-template #wordEntry let-modal>
    <div class="modal-heading-section border-bottom full-width text-center">
        <span class="modal-heading-text text-first-font">Seed Word Entry</span>
    </div>
    <div class="modal-description-section text-center">
        <app-autocomplete [words]="seedWords" (wordSelection)="onWordSelection($event)"></app-autocomplete>
    </div>
    <div class="full-width">
        <button type="submit" class="button-orange-outline full-width" (click)="sendWord()">Confirm</button>
    </div>
</ng-template>

<ng-template #pinEntry let-modal>
    <div class="modal-heading-section border-bottom text-align-center">
        <span class="modal-heading-text text-first-font">{{pinReEntry ? 'Re-e' : 'E'}}nter Pin</span>
    </div>
    <div class="pin-matrix" (keyup.enter)="sendPin()">
        <div class="pin-matrix-row">
            <div class="pin-matrix-circle" (click)="addPinDigit('7')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('8')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('9')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
        </div>
        <div class="pin-matrix-row">
            <div class="pin-matrix-circle" (click)="addPinDigit('4')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('5')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('6')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
        </div>
        <div class="pin-matrix-row">
            <div class="pin-matrix-circle" (click)="addPinDigit('1')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('2')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
            <div class="pin-matrix-circle" (click)="addPinDigit('3')">
                <div class="pin-matrix-inner-circle"></div>
            </div>
        </div>
    </div>
    <div class="pin-input-field-section d-flex align-items-center justify-content-end" (click)="deletePinDigit()">
        <input type="password" class="pin-input-field text-first-font full-width" value="{{pin}}" disabled>
        <i class="fa-solid fa-delete-left"></i>
    </div>
    <div class="confirm-button-section text-center">
        <button class="button-orange-outline full-width" type="button" (click)="sendPin()">Confirm</button>
    </div>
</ng-template>

<ng-template #confirmOnDevice let-modal>
    <div class="modal-heading-section border-bottom text-center">
        <span class="modal-heading-text text-first-font">CONFIRMATION</span>
    </div>
    <div class="modal-description-section text-center pb-0">
        <span class="modal-description-text">Confirm action on your Vault E</span>
    </div>
</ng-template>

<ng-template #confirmWordOnDevice let-modal>
    <div class="modal-heading-section border-bottom full-width text-center">
        <span class="modal-heading-text text-first-font">Seed Word {{seedWordConfirmation ? '(Confirmation)' : ''}}</span>
    </div>
    <div class="modal-description-section text-center pb-0">
        <span class="modal-description-text">Please {{seedWordConfirmation ? 'confirm that you correctly wrote down' : 'write down'}} the {{convertButtonRequestToSeedWord(currentButtonRequest)}} word</span>
    </div>
</ng-template>