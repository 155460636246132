import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { LogoComponent } from './logo/logo.component';
import { TOSComponent } from './tos/tos.component';

@NgModule({
  declarations: [ AutocompleteComponent, LogoComponent, TOSComponent],
  imports: [
    CommonModule
  ],
  exports : [
    AutocompleteComponent,
    LogoComponent,
    TOSComponent
  ]
})
export class SharedModule{}
