import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { WalletModule } from './wallet/wallet.module';
import { VaultService } from './services/vault.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

@NgModule({
  declarations: [
    AppComponent,
  ],
  exports:[
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    WalletModule,
    NgbModule,
  ],
  providers: [VaultService],
  bootstrap: [AppComponent]
})
export class AppModule { }
