<div id="hello-container">
    <div id="hello-logo" class="margin-bottom">
        <app-logo></app-logo>
    </div>
    <div id="hello-card" class="card text-align-center hello_card-container">
        <div>
            <span id="hello-status-text" class="text-16-px text-first-font"></span>
        </div>
        <div class="margin-top">
            <button id="hello-pair" class="button-orange-outline trezor-webusb-button padding-right padding-left">Pair Vault E</button>
        </div>
        <div class="margin-top">
            <span id="hello-status-note-text" class="text-14-px text-first-font"></span>
        </div>
    </div>
    <div class="margin-top hello_card-text">
        <span>This software only supports the Google Chrome web browser.</span>
    </div>
</div>