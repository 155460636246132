import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss'],

})
export class TOSComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
}