import { Component, ElementRef, OnInit, ViewChild, EventEmitter, Inject, Renderer2, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  inputs: ['words'],
  outputs: ['wordSelection']
})

export class AutocompleteComponent implements OnInit {
  private words: string[];

  @Output()
  public wordSelection: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit() {}

  selectWord = (event: any) => {
    const input = (<HTMLInputElement>this.document.getElementById('input-word'));
    this.wordSelection.emit(event.target.innerHTML);
    input.value = event.target.innerHTML;
    this.renderWords([]);
  }
  
  renderWords(words) {
    const matchedWordsContainer = this.document.getElementById('matched-words');
    matchedWordsContainer.innerHTML = '';
    matchedWordsContainer.hidden = words.length ? false : true;

    for (let i = 0; i < words.length; i++) {
      const newWord = this.renderer.createElement('li');
      newWord.innerHTML = words[i];
      newWord.addEventListener('click', this.selectWord);
      this.renderer.appendChild(matchedWordsContainer, newWord);
    }
  }
  
  filterWords(event) {
    const inputValue = event.target.value;
    const filterWords = this.words.filter((v, i) => {
      return inputValue === v.slice(0, inputValue.length);
    });

    this.renderWords(inputValue.length > 2 ? filterWords : []);
  }
}