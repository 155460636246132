import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelloComponent } from './hello/hello.component';
import { ResetComponent } from './reset/reset.component';
import { SharedModule } from '../shared/shared.module';
import { SetupComponent } from './setup/setup.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PortfolioOverviewComponent } from './portfolio/portfolio-overview/portfolio-overview.component';
import { PortfolioRouterComponent } from './portfolio/portfolio-router/portfolio-router.component';
import { PortfolioRouterOutletComponent } from './portfolio/portfolio-router-outlet/portfolio-router-outlet.component';
import { PortfolioSendComponent } from './portfolio/portfolio-send/portfolio-send.component';
import { PortfolioSettingsComponent } from './portfolio/portfolio-settings/portfolio-settings.component';
import { PortfolioTransactionsComponent } from './portfolio/portfolio-transactions/portfolio-transactions.component';
import { ToolsComponent } from './tools/tools.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  declarations: [
    HelloComponent,
    ResetComponent,
    SetupComponent,
    PortfolioComponent,
    PortfolioOverviewComponent,
    PortfolioRouterComponent,
    PortfolioRouterOutletComponent,
    PortfolioSendComponent,
    PortfolioSettingsComponent,
    PortfolioTransactionsComponent,
    ToolsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AutocompleteLibModule,
  ]
})
export class WalletModule { }
