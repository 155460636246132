import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { DEVICE_EVENT, DEVICE, UI_EVENT, UI } from 'trezor-connect';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDefaultModalConfig } from 'src/app/common/functions';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})

export class ResetComponent implements OnInit {
  @ViewChild('confirmOnDevice', { static: false })
  private confirmOnDevice: TemplateRef<any>;

  constructor(private vault: VaultService, private modal: NgbModal, private router: Router, private route: ActivatedRoute) {}

  async ngOnInit() {
    if (!this.vault.isInitialized()) this.vault.init(null);

    this.vault.subscribe(UI_EVENT, (event) => {
      if (event.type === UI.REQUEST_BUTTON) {
        this.modal.open(this.confirmOnDevice, { ...getDefaultModalConfig(), windowClass: 'modal-window confirm-on-device-modal confirmOnDevice-body' });
      }

      if (event.type === UI.CLOSE_UI_WINDOW) {
        this.modal.dismissAll();
      }
    });

    this.vault.subscribe(DEVICE_EVENT, (event) => {
      if (event.type === DEVICE.CONNECT) {
        this.vault.wipeDevice().then(results => {
          if (results.success) {
            this.router.navigate(['hello'], { queryParams: this.route.snapshot.queryParams }).then(() => window.location.reload());
          }
        });
      }
    });
  }

  backToSafety() {
    this.router.navigate(['hello'], { queryParams: this.route.snapshot.queryParams });
  }
}