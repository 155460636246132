import { Injectable } from '@angular/core';
import TrezorConnect, {
  Manifest,
  DEVICE,
  DEVICE_EVENT,
  TRANSPORT_EVENT,
  UI,
  UI_EVENT,
  ApplyFlags,
  ApplySettings,
  CommonParams,
  ComposeParams,
  CustomMessage,
  GetAccountInfo,
  GetAddress,
  GetPublicKey,
  PushTransaction,
  SignMessage,
  SignTransaction,
  VerifyMessage,
  ChangePin,
  CipherKeyValue,
  EthereumGetAddress,
  EthereumGetPublicKey,
  EthereumSignMessage,
  EthereumSignTransaction,
  EthereumVerifyMessage,
  RecoveryDevice,
  ResetDevice,
  FirmwareUpdate,
  FirmwareUpdateBinary,
  UiResponse,
  BlockchainSubscribe,
  Response,
  BlockchainGetTransactions,
  BlockchainEstimateFee,
  PrecomposeParams,
} from 'trezor-connect';
import { Router } from '@angular/router';
import { Device, DefaultMessage } from 'types/trezor-connect';

@Injectable({
  providedIn: 'root'
})

export class VaultService {
  private debugMode = false;
  private demoMode = false; // Set this if you want the service to provide dummy device and wallet data for testing w/o a real device.
  private currentDevice = undefined;
  private subs = {};
  private initialized = false;
  private knownDevice = false;
  private vBridgeConnection = false; // Assume until proven wrong.
  private vBridgeInstallerWindows = "https://firebasestorage.googleapis.com/v0/b/hodllabs-dev-26484.appspot.com/o/vbridge-0.1.0-win32-install.exe?alt=media&token=1f46553b-19ac-4ba9-824f-53afc3a4ecc6";
  private vBridgeInstallerMacOS = "https://firebasestorage.googleapis.com/v0/b/hodllabs-dev-26484.appspot.com/o/vbridge-0.1.0.pkg?alt=media&token=88112669-1450-42c2-8368-2f02e844a846";
  private latestFirmwareURL = "assets/firmware/firmware-v1.8.3.bin";
  private testBootloaderURL = "assets/firmware/firmware-test.bin";
  private coinGeckoBaseURL = 'https://api.coingecko.com/api/v3';
  private estimateFeesBaseURL = 'https://us-central1-hodllabs-staging-8207c.cloudfunctions.net/estimateFees';
  private emailSupportURL = 'https://us-central1-hodllabs-staging-8207c.cloudfunctions.net/sendSupportEmail';
  private passphrase = undefined;

  private initConfig = {
    debug: this.debugMode,
    connectSrc: 'https://hodllabs-vault-connect.web.app/',
    lazyLoad: false,
    manifest: {
      email: 'tyler@hodllabs.io',
      appUrl: 'https://wallet.hodllabs.io'
    },
    popup: false,
    webusb: true,
  };
  
  private demoDevice: Device = {
    type: 'unacquired',
    path: 'path',
    label: 'label'
  };

  

  constructor(private router: Router) {}

  init(settings: { manifest: Manifest }) {
    if (this.demoMode) {
      this.setCurrentDevice(this.demoDevice);
      return new Promise((resolve) => resolve(true));
    }

    if (this.initialized) return;

    if (!settings) {
      settings = this.initConfig;
    }

    // Transport Events
    TrezorConnect.on(TRANSPORT_EVENT, event => {
      this.publish(TRANSPORT_EVENT, event);
    }); 

    // Device Events
    TrezorConnect.on(DEVICE_EVENT, event => {
      this.publish(DEVICE_EVENT, event);

      if (event.type === DEVICE.CHANGED) {
        this.setCurrentDevice(event.payload);
      }

      if (event.type === DEVICE.CONNECT) {
        this.setCurrentDevice(event.payload);
      }

      if (event.type === DEVICE.DISCONNECT) {    
        this.clearPassphrase();  
        window.location.reload();
      }
    });

    // UI Events
    TrezorConnect.on(UI_EVENT, event => {
      // Handle everytime
      if (event.type === UI.SELECT_DEVICE) {
        if (event.payload.devices.length > 0) {
          this.uiResponse({type: UI.RECEIVE_DEVICE, payload: {device: event.payload.devices[0], remember: true}});
          this.setCurrentDevice(event.payload.devices[0]);
        }
      }

      this.publish(UI_EVENT, event);
    });

    return TrezorConnect.init(settings);
  }

  async sendSupportEmail(replyTo: string, feedback: string) {
    let params = {
      replyTo: replyTo,
      feedback: feedback
    };

    return fetch(this.emailSupportURL, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(results => {
      console.log(results);
      
      return results.json();
    }).catch(err => console.log(err));
  }

  async getReservation(options: any) {
    return fetch('https://api.sendwyre.com/v3/orders/reserve', {
      headers: {
        'Authorization': 'Bearer SK-WFPZFJV4-82T8WDQR-VJ36GU43-LUB776C4',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({...options, 'referrerAccountId': 'AC_7V847Y2JUAA'})
    }).then(results => results.json()).catch(err => console.error(err));
  }

  async getReservationQuote(reservationId: string) {
    return fetch(`https://api.sendwyre.com/v3/orders/reservation/${reservationId}`, {
      headers: {
        'Authorization': 'Bearer SK-WFPZFJV4-82T8WDQR-VJ36GU43-LUB776C4',
        'Content-Type': 'application/json'
      }
    }).then(results => results.json()).catch(err => console.error(err));
  }

  applyFlags(params: CommonParams & ApplyFlags) {
    return TrezorConnect.applyFlags(params);
  }

  applySettings(params: CommonParams & ApplySettings) {
    return TrezorConnect.applySettings(params);
  }

  backupDevice(params?: CommonParams) {
    return TrezorConnect.backupDevice(params);
  }

  bitcoinComposeTransaction(params: CommonParams & ComposeParams | PrecomposeParams) {
    return TrezorConnect.composeTransaction(params);
  }

  bitcoinEstimateFees() {
    return fetch(this.estimateFeesBaseURL + '?chain=btc').then(results => results.json());
  }

  bitcoinGetAccountInfo(params: CommonParams & GetAccountInfo) {
    return TrezorConnect.getAccountInfo(params);
  }

  bitcoinGetAddress(params: CommonParams & GetAddress) {
    return TrezorConnect.getAddress(params);
  }

  bitcoinGetPublicKey(params: CommonParams & GetPublicKey) {
    return TrezorConnect.getPublicKey(params);
  }

  bitcoinPushTransaction(params: CommonParams & PushTransaction) {
    return TrezorConnect.pushTransaction(params);
  }

  bitcoinSignMessage(params: CommonParams & SignMessage) {
    return TrezorConnect.signMessage(params);
  }

  bitcoinSignTransaction(params: CommonParams & SignTransaction) {
    return TrezorConnect.signTransaction(params);
  }

  bitcoinVerifyMessage(params: CommonParams & VerifyMessage) {
    return TrezorConnect.verifyMessage(params);
  }

  blockchainEstimateFees(params: CommonParams & BlockchainEstimateFee) {
    return TrezorConnect.blockchainEstimateFee(params);
  }

  blockchainGetTransactions(params: CommonParams & BlockchainGetTransactions) {
    return TrezorConnect.blockchainGetTransactions(params);
  }

  blockchainSubscribe(params: CommonParams & BlockchainSubscribe) {
    return TrezorConnect.blockchainSubscribe(params);
  }

  cancel(params?: CommonParams & string) {
    console.log("Canceling...");
    return TrezorConnect.cancel(params);
  }

  dispose() {
    console.log("Disposing...");
    return TrezorConnect.dispose();
  }

  changePin(params: CommonParams & ChangePin): Response<DefaultMessage> {
    if (this.demoMode) {
      this.publish(UI_EVENT, {type: UI.REQUEST_PIN});

      return new Promise((resolve) => {
        this.subscribe(UI_EVENT, (event) => {
          if (event.type == UI.RECEIVE_PIN) {
            this.demoDevice.features.pin_protection = true;
            resolve({success: true, id: 1, payload: {message: 'Successfully changed pin!'}});
          }
        });
      });
    }

    return TrezorConnect.changePin(params);
  }

  cipherKeyValue(params: CommonParams & CipherKeyValue) {
    return TrezorConnect.cipherKeyValue(params)
  }

  clearPassphrase() {
    this.passphrase = undefined;
  }

  coinGeckoGetBitcoinInfo() {
    return fetch(this.coinGeckoBaseURL + '/coins/bitcoin').then(results => results.json());
  }

  coinGeckoGetEthereumInfo() {
    return fetch(this.coinGeckoBaseURL + '/coins/ethereum').then(results => results.json());
  }

  customMessage(params: CommonParams & CustomMessage) {
    return TrezorConnect.customMessage(params);
  }

  ethereumComposeTransaction(params: CommonParams & ComposeParams) {
    return TrezorConnect.composeTransaction(params);
  }

  ethereumEstimateFees() {
    return fetch(this.estimateFeesBaseURL + '?chain=eth').then(results => results.json());
  }

  ethereumGetAccountInfo(params: CommonParams & GetAccountInfo) {
    return TrezorConnect.getAccountInfo(params);
  }

  ethereumGetAddress(params: CommonParams & EthereumGetAddress) {
    return TrezorConnect.ethereumGetAddress(params);
  }

  ethereumGetPublicKey(params: CommonParams & EthereumGetPublicKey) {
    return TrezorConnect.ethereumGetPublicKey(params);
  }

  ethereumPushTransaction(params: CommonParams & PushTransaction) {
    return TrezorConnect.pushTransaction(params);
  }

  ethereumSignMessage(params: CommonParams & EthereumSignMessage) {
    return TrezorConnect.ethereumSignMessage(params);
  }

  ethereumSignTransaction(params: CommonParams & EthereumSignTransaction) {
    return TrezorConnect.ethereumSignTransaction(params);
  }

  ethereumVerifyMessage(params: CommonParams & EthereumVerifyMessage) {
    return TrezorConnect.ethereumVerifyMessage(params);
  }

  fetchFirmware(version?: string) {
    let firmwareURL;

    switch(version) {
      case 'latest':
        firmwareURL = this.latestFirmwareURL;
        break;
      case 'test':
        firmwareURL = this.testBootloaderURL;
        break;
      default:
        firmwareURL = this.latestFirmwareURL;
    }

    return fetch(firmwareURL, {mode: 'no-cors', headers: {'content-type': 'application/octet-stream'}}).then(results => {
      return results.arrayBuffer();
    });
  }

  getCurrentDevice() : Device {
    return this.currentDevice;
  }

  getFeatures(params?: CommonParams) {
    return TrezorConnect.getFeatures(params);
  }

  getPassphrase() {
    if(window.localStorage.getItem('pp')) {
      this.setPassphrase(window.localStorage.getItem('pp'));
      window.localStorage.clear();
    }
    return this.passphrase;
  }

  getMacOSInstallerLink() {
    return this.vBridgeInstallerMacOS;
  }

  getWindowsInstallerLink() {
    return this.vBridgeInstallerWindows;
  }

  isDebugMode() : boolean {
    return this.debugMode;
  }

  isDemoMode() : boolean {
    return this.demoMode;
  }

  isInitialized() : boolean {
    return this.initialized;
  }

  isKnownDevice() : boolean {
    return this.knownDevice;
  }

  private publish(type: any, event: any) {
    if (!this.subs[type]) return;
    this.subs[type].forEach((cb: Function) => cb(event));
  }

  recoverDevice(params: CommonParams & RecoveryDevice) {
    // Note: The trezor-connect method name is 'recoveryDevice' with a 'y'.
    params.type = 0; // This must be forced; Vault devices dont support seed word matrix types (type 1)
    return TrezorConnect.recoveryDevice(params);
  }

  renderWebUSBButton() {
    if (this.demoMode) return;
    return TrezorConnect.renderWebUSBButton();
  }

  resetDevice(params: CommonParams & ResetDevice): Response<DefaultMessage> {
    // This function is used to init a device but is poorly named.
    if (this.demoMode) {
      this.demoDevice.label = params.label;
      this.demoDevice.features.initialized = true;
      this.demoDevice.features.label = params.label;

      return new Promise((resolve) => resolve({success: true, id: 1, payload: {message: 'Successfully reset device!'}}));
    }

    return TrezorConnect.resetDevice(params);
  }

  setCurrentDevice(device: Device) {
    this.currentDevice = device;
  }

  setDemoMode(enabled: boolean) {
    this.demoMode = enabled;
  }
  
  setInitialized(initialized: boolean = true) {
    this.initialized = initialized;
  }

  setPassphrase(passphrase: string = '') {
    this.passphrase = passphrase;
  }

  subscribe(event: any, cb: Function) {
    if (!this.subs[event]) {
      this.subs[event] = [];
    }
    
    this.subs[event].push(cb);
  }
  
  uiResponse(response: UiResponse) {
    if (this.demoMode) {
      if (response.type == UI.RECEIVE_PIN) {
        this.publish(UI_EVENT, {type: UI.RECEIVE_PIN});
      }

      return
    }

    return TrezorConnect.uiResponse(response);
  }

  unsubscribeAll() {
    this.subs = {};
  }

  updateFirmware(params: (CommonParams & FirmwareUpdate) | (CommonParams & FirmwareUpdateBinary)) {
    return TrezorConnect.firmwareUpdate(params);
  }

  vBridgeStatus() : boolean {
    return this.vBridgeConnection;
  }

  wipeDevice(params?: CommonParams) {
    return TrezorConnect.wipeDevice(params);
  }
}
