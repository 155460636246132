import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { DEVICE_EVENT, TRANSPORT_EVENT, UI, UI_EVENT, DEVICE } from 'trezor-connect';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDefaultModalConfig } from 'src/app/common/functions';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})

export class ToolsComponent implements OnInit {
  @ViewChild('confirmOnDevice', { static: false })
  private confirmOnDevice: TemplateRef<any>;

  public toolsPairButton: HTMLElement;
  public toolsPairCard: HTMLElement;
  public toolsToolCard: HTMLElement;
  public errorMessage: string;
  public pairedDevice: boolean;
  private firmwareBinary: string | ArrayBuffer;
  public firmwareUpload: HTMLElement;
  public firmwareSelection: string;
  public firmwareSet = [
    { id: 'latest', label: 'Latest Production Firmware' },
    { id: 'test-bl-v0.6.0', label: 'Test Firmware (Bootloader v0.6.0)' }
  ];

  constructor(private vault: VaultService, private router: Router, private route: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit() {
    this.vault.subscribe(DEVICE_EVENT, (event) => {
      if (event.type === DEVICE.CONNECT || event.type === DEVICE.DISCONNECT) {
        this.isPaired();
      }
    });

    this.vault.subscribe(UI_EVENT, (event) => {
      if (event.type === UI.CLOSE_UI_WINDOW) {
        this.modal.dismissAll();
      }

      if (event.type === UI.REQUEST_BUTTON) {
        this.modal.open(this.confirmOnDevice, { ...getDefaultModalConfig(), windowClass: 'modal-window confirm-on-device-modal' });
      }
    });

    this.firmwareUpload = document.getElementById('tools-firmware-upload');
    this.firmwareUpload.addEventListener('change', this.readFirmware);
    this.toolsPairButton = document.getElementById('tools-pair-button');
    this.toolsPairButton.style.display = 'inline-block';
    this.toolsPairCard = document.getElementById('tools-pair-card');
    this.toolsPairCard.style.display = 'none';
    this.toolsToolCard = document.getElementById('tools-tool-card');
    this.toolsToolCard.style.display = 'none';

    if (this.vault.isInitialized()) {
      this.isPaired();
      this.vault.renderWebUSBButton();
    } else {
      this.vault.init(null).then(() => {
        setTimeout(() => {
          this.isPaired();
          this.vault.setInitialized();
          this.vault.renderWebUSBButton();
        }, 500);
      });
    }
  }

  readFirmware() {
    let reader = new FileReader();

    reader.onload = () => {
      this.firmwareBinary = reader.result;
      console.log(this.firmwareBinary);
    }

    reader.readAsArrayBuffer(this['files'][0]);
  }

  isPaired() {
    if (this.vault.getCurrentDevice()) {
      this.pairedDevice = true;
      this.toolsPairCard.style.display = 'none';
      this.toolsToolCard.style.display = 'block';
    } else {
      this.pairedDevice = false;
      this.toolsPairCard.style.display = 'block';
      this.toolsToolCard.style.display = 'none';
    }
  }

  wipeDevice() {
    this.vault.wipeDevice().then(results => {
      console.log(results);
    })
  }

  installFirmware() {
    if (this.firmwareBinary) {
      this.updateFirmware(this.firmwareBinary);
    } else {
      this.vault.fetchFirmware(this.firmwareSelection).then(bytes => {
        this.updateFirmware(bytes);
      });
    }
  }

  updateFirmware(bytes) {
    this.vault.updateFirmware({ binary: Buffer.from(bytes) }).then(results => {
      if (results.payload) {
        if (results.payload['error'] === UI.NOT_IN_BOOTLOADER) {
          this.errorMessage = 'Device is not in Bootloader Mode';
        }
      }
    });
  }
}