import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css',]
})

export class AppComponent {

  constructor() {
    var theme = localStorage.getItem('data-theme') ? localStorage.getItem('data-theme') : ''

    document.documentElement.setAttribute('data-theme', theme);
  }
  title = 'hodllabs';
}

