<div id="reset-container">
    <div class="card text-align-center">
        <span>Please reconnect your Vault E to initiate a device reset or <a href="#" class="text-link" (click)="backToSafety()">go back to safety</a>.</span>
    </div>
</div>

<ng-template #confirmOnDevice let-modal>
    <div class="modal-heading-section border-bottom full-width text-center">
        <span class="modal-heading-text text-first-font">CONFIRMATION</span>
    </div>
    <div class="modal-description-section text-center pb-0">
        <span class="modal-description-text ">Confirm action on your Vault E</span>
    </div>
</ng-template>