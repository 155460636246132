<div id="tools-container">
    <div id="tools-pair-card" class="card text-align-center">
        <div>
            <button id="tools-pair-button" class="trezor-webusb-button padding-right padding-left">Pair Vault E</button>
        </div>
    </div>
    <div id="tools-tool-card" class="card text-align-center">
        <div>
            <select [(ngModel)]="firmwareSelection">
                <option *ngFor="let firmware of firmwareSet" [value]="firmware.id">{{firmware.label}}</option>
            </select>
        </div>
        <div class="margin-top">
            <input id="tools-firmware-upload" type="file">
        </div>
        <div class="margin-top">
            <button (click)="installFirmware()">Install Firmware</button>
        </div>
    </div>
    <div class="margin-top">
        <span class="error-text">{{errorMessage}}</span>
    </div>
</div>

<ng-template #confirmOnDevice let-modal>
    <div class="modal-heading-section border-bottom full-width text-center">
        <span class="modal-heading-text text-first-font">CONFIRMATION</span>
    </div>
    <div class="modal-description-section text-center pb-0">
        <span class="modal-description-text">Confirm action on your Vault E</span>
    </div>
</ng-template>