<div id="tos">
  <div id="page_1">
      <p class="p0 ft0">Hodllabs, Inc.</p>
      <p class="p1 ft1">Terms of Service</p>
      <p class="p2 ft2">Overview</p>
      <p class="p3 ft3">This Agreement is between you (“you”, “your”, or “User”) and Hodllabs, Inc., a
          Delaware corporation (“Hodllabs”, “us”, “we,'' “our”, or the “Company”). By visiting, using, or
          accessing (“using”, “use”) our Website, products and other services (“Services”) you agree to be
          bound by the following terms and conditions ("Terms of Service", "Terms"), including those
          additional terms and conditions and policies referenced herein and/or available by hyperlink.
          These Terms of Service apply to all users of the site, including without limitation users who
          are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
      <p class="p3 ft4">Please read these Terms of Service carefully before using our Services. If you do
          not agree to all the terms and conditions of this Agreement, then you may not use any of our
          Services.</p>
      <p class="p4 ft4">Your use of our Services is also subject to Our Privacy Policy, which is available
          at <a href="https://hodllabs.io/privacy.html" target="_blank">https://hodllabs.io/privacy.html</a></p>
      <p class="p5 ft5">If you continue to use our Services after you have learned of any changes or
          amendments hereto, you are deemed to have accepted these amendments and changes. However, if you
          do not accept them, you are not entitled to use our Services and you shall stop using our
          Services immediately.</p>
      <p class="p6 ft2"><span class="ft2">1.</span><span class="ft6">Definition</span></p>
      <p class="p7 ft4"><span class="ft4">1.1.</span><span class="ft7">“Vault E”, “Device” </span>means an
          electronic device, developed and distributed by Hodllabs, generally referred to as a “hardware
          wallet”, used to manage and secure Users’ Private Keys.</p>
      <p class="p8 ft4"><span class="ft4">1.2.</span><span class="ft7">“Private Key” </span>means a
          critical piece of alphanumeric data used to authorize outgoing transactions on blockchain
          networks.</p>
      <p class="p8 ft4"><span class="ft4">1.3.</span><span class="ft7">“Website” </span>means all websites
          owned and operated by Hodllabs under the domain name hodllabs.io and all its subdomains.</p>
      <p class="p9 ft5"><span class="ft4">1.4.</span><span class="ft8">“App”, “Wallet”, “Webapp”
          </span>means any software program developed by us through which you can assess your Device,
          manage your Cryptocurrency Coins, and any other feature we may provide in the future through the
          App.</p>
      <p class="p9 ft5"><span class="ft4">1.5.</span>
          <span class="ft8">“24-Word</span><span class="ft9"> Recovery Seed” </span>means a
          chain of twenty-four (24) randomly selected words from a list of available words
          used to generate the Private Key. This seed is used to back-up in order to restore
          access to your Private Key on a Vault E or compatible device.
      </p>
      <p class="p9 ft5"><span class="ft4">1.6.</span><span class="ft8">“Cryptocurrency Coins”, “Coins”
          </span>means types of digital money asset which can be transmitted with blockchain technologies.
          Such Coins include Bitcoin, Ethereum, and other similar asset which shall be added in the
          future.</p>
      <p class="p10 ft4"><span class="ft4">1.7.</span><span class="ft7">“Pin” </span>means the numeric
          password chosen by the user to unlock a Vault E.</p>
      <p class="p11 ft4"><span class="ft4">1.8.</span><span class="ft10">“</span><span
              class="ft11">Passphrase</span>” means an alphanumeric password chosen by users at the time
          of setup. This behaves as a password and used in conjunction with the 24-Word
          Recovery Seed to generate the Private Key.</p>
      <p class="p12 ft2"><span class="ft2">2.</span><span class="ft6">Eligibility</span></p>
      <p class="p7 ft4"><span class="ft4">2.1.</span><span class="ft12">To be eligible to use our
              services, you must be at least 18 years old or be deemed able to enter into a legal contract
              in your country of residence.</span></p>
      <p class="p9 ft5"><span class="ft4">2.2.</span><span class="ft13">You acknowledge that you have full
              legal capacity and that your legal capacity has not been previously limited to an extent
              that could prevent you from accepting these Terms, using our Services and managing your
              Cryptocurrency Coins.</span></p>
      <p class="p9 ft5"><span class="ft4">2.3.</span><span class="ft13">If you use our Service on behalf
              of a legal entity, you represent that it has been duly organized and exists in accordance
              with the laws of the governing jurisdiction and you represent that you are authorized to act
              on behalf of the legal entity including but not limited to, binding the legal entity in a
              contractual manner.</span></p>
      <p class="p13 ft15"><span class="ft4">2.4.</span><span class="ft14">You can only use our Services if
              permitted under the laws of your jurisdiction. You are responsible to ensure you are in
              compliance with all laws, rules, and regulations that apply to you.</span></p>
      <p class="p14 ft2"><span class="ft2">3.</span><span class="ft6">Vault E and Wallet</span></p>
      <p class="p15 ft4"><span class="ft4">3.1.</span><span class="ft10">Vault E and the Wallet software
              is designed to allow to manage your Private Key generated from a set of </span>
          24-Word Recovery Seed.
      </p>
      <p class="p16 ft4"><span class="ft4">3.1.1.</span>
          <span class="ft16">24-word</span> seed phrase is either generated within the Device
          itself or provided by the user.
      </p>
      <p class="p17 ft4"><span class="ft4">3.2.</span><span class="ft10">Vault E and the Wallet software
              allows users the following core functionality:</span></p>
      <p class="p18 ft4"><span class="ft4">3.2.1.</span><span class="ft16">Store user’s Private Key</span>
      </p>
      <p class="p19 ft4"><span class="ft4">3.2.2.</span><span class="ft16">Allow user to view their
              balance of all supported Cryptocurrency Coins</span></p>
      <p class="p18 ft4"><span class="ft4">3.2.3.</span><span class="ft16">Allow user to view, send,
              and/or receive transactions on supported Cryptocurrency Coins</span></p>
      <p class="p20 ft4"><span class="ft4">3.3.</span><span class="ft12">Vault E and the Wallet software
              are </span>
          non-custodial services. This means that we do not store, have access to, or manage
          your Coins or your Private Key.
      </p>
      <p class="p21 ft4"><span class="ft4">3.4.</span><span class="ft10">Transaction performed using Vault
              E and the Wallet software takes place strictly on the respective blockchain network. Said
              network is neither owned not controlled by Hodllabs.</span></p>
      <p class="p9 ft5"><span class="ft4">3.5.</span><span class="ft13">Ensuring the accuracy of details
              of a transaction performed on Vault E and the Wallet software, including but not limited to,
              coin amount and recipient address is the sole responsibility of the user.</span></p>
      <p class="p9 ft3"><span class="ft4">3.6.</span><span class="ft17">We do not assume, under any
              circumstances, any liability for any obligations of the third party receiving the
              transaction. We are not in any way a part of such a legal relationship and any subsequent
              claims and disputes shall always be settled solely between you and the respective third
              party. You shall indemnify and hold Hodllabs harmless for any liability arising out of or
              accruing from your transaction with a third party.</span></p>
      <p class="p22 ft5"><span class="ft4">3.7.</span><span class="ft13">When submitting a transaction,
              Wallet shall calculate for your information an approximate amount of fiat currency equal to
              the amount of Cryptocurrency you endeavor to send. This calculation is only for your
              information and it is based on the exchange rates provided by a third- party provider,
              CoinGecko </span><a href="https://www.coingecko.com/en">(</a><a
              href="https://www.coingecko.com/en"><span
                  class="ft18">https://www.coingecko.com/en</span></a>).</p>
      <p class="p23 ft5"><span class="ft4">3.8.</span><span class="ft13">Use of Vault E or Wallet software
              with unsupported Cryptocurrency Coins is strictly prohibited. If a user engages in such
              activities, it is the sole responsibility of the user for any potential loss of coin.
              Hodllabs shall not be held liable for such as loss.</span></p>
      <p class="p1 ft2"><span class="ft2">4.</span><span class="ft6">Accuracy, Completeness and Timeliness
              of Information</span></p>
      <p class="p24 ft3"><span class="ft4">4.1.</span><span class="ft17">We are not responsible for
              information made available on this site that is not accurate, complete or current. The
              material on this site is provided for general information only and should not be relied upon
              or used as the sole basis for making decisions without consulting primary, more accurate,
              more complete or more timely sources of information. Any reliance on the material on this
              site is at your own risk.</span></p>
      <p class="p11 ft5"><span class="ft4">4.2.</span><span class="ft13">This site may contain certain
              historical information. Historical information is not necessarily current and is provided
              for your reference only. We reserve the right to modify the contents of this site at any
              time, but we have no obligation to update any information on our site. You agree that it is
              your responsibility to monitor changes to our site.</span></p>
      <p class="p14 ft2"><span class="ft2">5.</span><span class="ft6">Risk</span></p>
      <p class="p25 ft4"><span class="ft4">5.1.</span><span class="ft10">No retrieval of Private
              Keys</span></p>
      <p class="p26 ft3"><span class="ft4">5.1.1.</span><span class="ft19">The only existing backup is
              with you. We do not have access to or store passwords, </span>
          24-Word Recovery Seed, Private Keys, Passphrases, transaction history, pin, or
          other credentials associated with your use of the services. We are not able to help you retrieve
          your credentials. You are solely responsible for remembering, storing, and keeping your
          credentials in a secure location. Any third party with knowledge of one or more of your 24- word
          recovery seed or pin can gain control of the Private Keys associated with your Vault E or of the
          24-Word Recovery Seed. You understand the risk of providing a third party with
          sensitive information.
      </p>
      <p class="p27 ft4"><span class="ft4">5.2.</span><span class="ft10">Taxes</span></p>
      <p class="p26 ft5"><span class="ft4">5.2.1.</span><span class="ft20">We are not responsible for, nor
              can we offer, any specific advice regarding any taxes or </span>
          customs-related fees that you may incur or be liable to pay by using our Services.
          It is your sole responsibility to determine whether, and to what extent, any taxes apply to any
          transactions you conduct through the Service.
      </p>
      <p class="p28 ft4"><span class="ft4">5.3.</span><span class="ft10">Technology</span></p>
      <p class="p29 ft22"><span class="ft4">5.3.1.</span><span class="ft21">Users understand that some of
              the technology supported or made available through our ervices are new, untested and outside
              of our control. Advances in cryptography, or other technical advances such as the
              development of quantum computers, could present risks to blockchain networks which could
              result in the theft or loss of Cryptocurrency assets. Other</span></p>
      <p class="p30 ft4">adverse changes in market forces or in the technology, broadly construed, may
          prevent or compromise our performance under these Terms.</p>
      <p class="p27 ft4"><span class="ft4">5.4.</span><span class="ft10">Cybersecurity</span></p>
      <p class="p31 ft3"><span class="ft4">5.4.1.</span><span class="ft19">Hackers or other groups or
              organizations may attempt to interfere with our products and information systems in several
              ways, including without limitation denial of service attacks, </span>
          side-channel attacks, spoofing, smurfing, malware attacks, or consensus-based
           attacks. There may be additional risks that we have not foreseen or identified in these
          Terms. Before you use our Services, you are strongly encouraged to carefully assess whether your
          financial situation and risk tolerance is compatible with such use. For the avoidance of doubt,
          and notwithstanding the generality of the Limitation of Liability section, you hereby agree that
          Hodllabs shall have no liability for any loss that incurs as a result of the risks highlighted
          in this section.
      </p>
      <p class="p1 ft2"><span class="ft2">6.</span><span class="ft6">Acceptable Use</span></p>
      <p class="p24 ft5"><span class="ft4">6.1.</span><span class="ft13">As a user of our Services, we
              grant you a limited, personal, </span>
          non-commercial,
          non-exclusive, non- transferable, and revocable license to use our Services. When
          using our Services, you are required to adhere to the following policies:
      </p>
      <p class="p32 ft3"><span class="ft4">6.1.1.</span><span class="ft19">Do no harm. You agree (i) not
              to distribute any virus or other harmful computer code through our systems, (ii) not to use
              any robot, spider, crawler, scraper or other automated means or interface not provided by us
              to access our Services or to extract data, (iii) not to provide false, inaccurate, or
              misleading information, and (iv) not to take any action that may impose an unreasonable or
              disproportionately large load on our or any of our </span>
          third-party providers’ infrastructure.
      </p>
      <p class="p33 ft15"><span class="ft4">6.1.2.</span><span class="ft23">Don’t circumvent our security.
              You agree not to bypass, circumvent, or attempt to bypass or circumvent any measures that we
              may use to prevent or restrict access to our Services.</span></p>
      <p class="p34 ft22"><span class="ft4">6.1.3.</span><span class="ft21">Don’t break the law. You agree
              that you will not violate any laws when using our Services. This includes any local,
              provincial, state, federal, national, or international laws that may apply to you. You agree
              that you will not use our services to pay for, support, or otherwise engage in any illegal
              activities including, but not limited to, fraud, illegal gambling, money laundering, or
              terrorist activities. You further agree not to encourage or induce any third party to engage
              in any of the activities prohibited under this section.</span></p>
      <p class="p29 ft22"><span class="ft4">6.1.4.</span><span class="ft21">Don’t interfere. You agree
              that you will not use or attempt to use another user’s Wallet without authorization, or use
              our Services in any manner that could interfere with, disrupt, negatively affect, or inhibit
              other users from fully enjoying our Services, or that could damage, disable, overburden or
              impair the functioning of our Services in any manner.</span></p>
      <p class="p35 ft4"><span class="ft4">6.2.</span><span class="ft10">Any use of our Services other
              than as specifically authorized in these Terms, without our prior written permission, is
              strictly prohibited and will terminate your license to use our Services.</span></p>
      <p class="p0 ft2"><span class="ft2">7.</span><span class="ft6">Availability</span></p>
      <p class="p36 ft22"><span class="ft4">7.1.</span><span class="ft24">We may change, update or suspend
              the Services, temporarily or indefinitely, so as to carry out work including, but not
              limited to, firmware and software updates, maintenance operations, amendments to the
              servers, bug fixes etc. We will make reasonable efforts to give you prior notice of any
              significant disruption. We cannot guarantee uninterrupted availability of our Services and
              you shall hold us harmless for any loss incurred due to interruption in our Services.</span>
      </p>
      <p class="p20 ft4"><span class="ft4">7.2.</span><span class="ft10">Under no circumstances,
              irrespective whether you have been notified in advance or not, are we liable for any losses
              incurred by you as a result of the unavailability of our Service.</span></p>
      <p class="p1 ft2"><span class="ft2">8.</span><span class="ft6">Warranties and Disclaimers</span></p>
      <p class="p37 ft26"><span class="ft4">8.1.</span><span class="ft25">The Service is provided on an
              “as is” and “as available” basis. Use of the Service is at your own risk. To the maximum
              extent permitted by applicable law, the Service is provided without warranties of any kind,
              whether express or implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, or </span>
          non-infringement. No advice or information, whether oral or written, obtained by
          you from Hodllabs or through the Service will create any warranty not expressly stated herein.
          Without limiting the foregoing, Hodllabs, its subsidiaries, its affiliates, and its licensors do
          not warrant that the content is accurate, reliable or correct; that the Service will meet your
          requirements; that the Service will be available at any particular time or location,
          uninterrupted or secure; that any defects or errors will be corrected; or that the Service is
          free of viruses or other harmful components. Any content downloaded or otherwise obtained
          through the use of the Service is downloaded at your own risk and you will be solely responsible
          for any damage to your computer system or mobile device or loss of data that results from such
          download or your use of the Service.
      </p>
      <p class="p38 ft5"><span class="ft4">8.2.</span><span class="ft13">Hodllabs does not warrant,
              endorse, guarantee, or assume responsibility for any product or service advertised or
              offered by a third party through the Service or any hyperlinked website or service, and
              Hodllabs will not be a party to or in any way monitor any transaction between you and
          </span>
          third-party providers of products or Services.
      </p>
      <p class="p39 ft2"><span class="ft2">9.</span><span class="ft6">Limitation of liability</span></p>
      <p class="p36 ft28"><span class="ft4">9.1.</span><span class="ft27">You expressly understand and
              agree that Hodllabs and its owners, shareholders, directors and employees shall not be
              liable to you for any direct, indirect, incidental, special, consequential or exemplary
              damages, including, but not limited to, damages for loss of profits, goodwill, use, data,
              cost of procurement of substitute goods or services, or other intangible losses, resulting
              from:</span></p>
      <p class="p16 ft4"><span class="ft4">9.1.1.</span><span class="ft16">The use or inability to use the
              Services;</span></p>
      <p class="p40 ft4"><span class="ft4">9.1.2.</span><span class="ft16">Any changes made to the Service
              or any suspension or cessation of the services or any part thereof;</span></p>
      <p class="p16 ft4"><span class="ft4">9.1.3.</span><span class="ft16">The unauthorized access to or
              alteration of your transmissions or data;</span></p>
      <p class="p41 ft4"><span class="ft4">9.1.4.</span><span class="ft16">The deletion of, corruption of,
              or failure to store and/or send or receive your transmissions or data on or through the
              Service;</span></p>
      <p class="p42 ft4"><span class="ft4">9.1.5.</span><span class="ft16">Any other matter relating to
              the Service.</span></p>
      <p class="p17 ft4"><span class="ft4">9.2.</span><span class="ft10">Hodllabs assumes no liability or
              responsibility for any:</span></p>
      <p class="p19 ft4"><span class="ft4">9.2.1.</span><span class="ft16">Errors, mistakes, or
              inaccuracies of content;</span></p>
      <p class="p40 ft4"><span class="ft4">9.2.2.</span><span class="ft16">Personal injury or property
              damage, of any nature whatsoever, resulting from your access to or use of our
              Service;</span></p>
      <p class="p43 ft4"><span class="ft4">9.2.3.</span><span class="ft16">Any unauthorized access to or
              use of our secure servers and/or any and all personal information stored therein;</span></p>
      <p class="p16 ft4"><span class="ft4">9.2.4.</span><span class="ft16">Any interruption or cessation
              of transmission to or from the Service;</span></p>
      <p class="p40 ft4"><span class="ft4">9.2.5.</span><span class="ft16">Any bugs, viruses, trojan
              horses, or the like that may be transmitted to or through our Service by any third
              party;</span></p>
      <p class="p32 ft5"><span class="ft4">9.2.6.</span><span class="ft20">Any errors or omissions in any
              content or for any loss or damage incurred as a result of the use of any content posted,
              emailed, transmitted, or otherwise made available through the Service;</span></p>
      <p class="p16 ft4"><span class="ft4">9.2.7.</span><span class="ft16">User Content or the defamatory,
              offensive, or illegal conduct of any third party.</span></p>
      <p class="p44 ft2"><span class="ft2">10.</span><span class="ft29">Governing Law</span></p>
      <p class="p15 ft4"><span class="ft4">10.1.</span><span class="ft30">This Agreement will be construed
              in accordance with and governed by the laws of Florida without regard to conflict of laws
              principles</span></p>
      <p class="p1 ft2"><span class="ft2">11.</span><span class="ft29">Arbitration</span></p>
      <p class="p45 ft3"><span class="ft4">11.1.</span><span class="ft31">Any controversy or claim arising
              out of or relating to this Agreement, or the breach thereof, shall be settled by arbitration
              administered by the American Arbitration Association under its Commercial Arbitration Rules.
              The number of arbitrators shall be one (1) and the place of arbitration shall be Seminole
              County, Florida. Judgment on the award rendered by the arbitrator may be entered in any
              court having jurisdiction thereof.</span></p>
      <p class="p44 ft2"><span class="ft2">12.</span><span class="ft29">Indemnification</span></p>
      <p class="p45 ft4"><span class="ft4">12.1.</span><span class="ft30">You will indemnify and hold us,
              our affiliates, subcontractors, licensors and agents and our and their owners, shareholders,
              directors, offices, employees and representatives, harmless from and against all damages
              arising from or related to:</span></p>
      <p class="p46 ft4"><span class="ft4">12.1.1.</span><span class="ft32">Your breach of these Terms;
              and</span></p>
      <p class="p18 ft4"><span class="ft4">12.1.2.</span><span class="ft33">Third party claims arising
              from or related to your acts, omissions or Transactions.</span></p>
  </div>
</div>