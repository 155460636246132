import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'portfolio-router-outlet',
  templateUrl: './portfolio-router-outlet.component.html',
  styleUrls: ['./portfolio-router-outlet.component.css'],

})
export class PortfolioRouterOutletComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
}