import { Component, OnInit, ViewChild, ContentChildren, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VaultService } from '../../services/vault.service';
import { DEVICE_EVENT, TRANSPORT_EVENT, UI_EVENT, UI, Device } from 'trezor-connect';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDefaultModalConfig } from '../../common/functions';
import { seedWords } from '../../common/seed-words';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})

export class SetupComponent implements OnInit {
  @ViewChild('pinEntry', {static: false})
  private pinEntry: TemplateRef<any>;
  @ViewChild('wordEntry', {static: false})
  private wordEntry: TemplateRef<any>;
  @ViewChild('confirmOnDevice', {static: false})
  private confirmOnDevice: TemplateRef<any>;
  @ViewChild('confirmWordOnDevice', {static: false})
  private confirmWordOnDevice: TemplateRef<any>;
  @ViewChild('passphraseEntry', {static: false})
  private passphraseEntry: TemplateRef<any>;

  public device: Device;

  // Define all our various 'screens' for this component view -- remember to add new additions to hideAllScreens() as well.
  public installFirmwareScreen: boolean = true;
  public defaultSetupScreen: boolean = false;
  public newWalletSetupTermsOfServiceScreen: boolean = false;
  public newWalletSetupPassphraseScreen: boolean = false;
  public newWalletSetupPinScreen: boolean = false;
  public newWalletSetupSeedScreen: boolean = false;
  public newWalletSetupNameScreen: boolean = false;
  public newWalletReconnectNotice: boolean = false;
  public walletRecoveryTermsOfServiceScreen: boolean = false;
  public walletRecoveryPassphraseScreen: boolean = false;
  public walletRecoverySeedScreen: boolean = false;
  public walletRecoverySeedEntryScreen: boolean = false;
  public walletRecoveryPinScreen: boolean = false;
  public walletRecoveryNameScreen: boolean = false;
  public resetDeviceContinue: boolean = false;
  public deviceName = new FormControl('');
  public passphrase = new FormControl('');
  public seedWord: string;
  public pin: string;
  public pinReEntry: boolean = false;
  public passphraseError: boolean;
  public currentButtonRequest: number;
  public currentSeedWord: string;
  public seedWordConfirmation: boolean = false;

  public seedWords = seedWords;

  constructor(private vault: VaultService, private modal: NgbModal, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    if (!this.vault.isInitialized()) this.router.navigate(
      ['hello'],
      { queryParams: this.route.snapshot.queryParams }).then(() => window.location.reload());

    let debugMode = this.vault.isDebugMode();
    
    this.vault.subscribe(DEVICE_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.vault.subscribe(TRANSPORT_EVENT, (event) => {
      if (debugMode) console.log(event);
    });

    this.vault.subscribe(UI_EVENT, (event) => {
      if (debugMode) console.log(event);

      if (event.type === UI.REQUEST_PIN) {
        this.modal.dismissAll();
        this.modal.open(this.pinEntry, {...getDefaultModalConfig(), windowClass: 'modal-window pin-entry-modal'});
      }

      if (event.type === UI.REQUEST_BUTTON) {
        if (event.payload.code === 'ButtonRequest_ConfirmWord') { // This event will fire 48 times; twice for each word.
          if (this.currentButtonRequest === 48) {
            this.currentButtonRequest = 1;
            this.seedWordConfirmation = false;
          } else {
            this.currentButtonRequest += 1;
          }
          
          if (this.currentButtonRequest === 25) this.seedWordConfirmation = true;

          this.modal.dismissAll();
          this.modal.open(this.confirmWordOnDevice, {...getDefaultModalConfig(), windowClass: 'modal-window confirmWordOnDevice-modal'});
        } else {
          this.modal.open(this.confirmOnDevice, {...getDefaultModalConfig(), windowClass: 'modal-window confirm-on-device-modal'} );
        }
      }

      if (event.type === UI.CLOSE_UI_WINDOW) {
        this.modal.dismissAll();
      }

      if (event.type === UI.REQUEST_WORD) {
        this.modal.dismissAll();
        this.modal.open(this.wordEntry, {...getDefaultModalConfig(), windowClass: 'modal-window wordEntry-modal'});
      }

      if (event.type === UI.REQUEST_PASSPHRASE) {
        let passphrase = this.vault.getPassphrase();
        if(passphrase !== undefined) {
          this.vault.uiResponse({type: UI.RECEIVE_PASSPHRASE, payload: {value: passphrase, save: true, passphraseOnDevice: false}});
        } else {
          this.modal.dismissAll();
          this.modal.open(this.passphraseEntry, getDefaultModalConfig());
        }
      }
    });

    this.device = this.vault.getCurrentDevice();

    this.hideAllScreens()

    if (this.device.firmware === "none") {
      this.installFirmwareScreen = true;
    } else {
      if (this.device.mode === "bootloader") {
        this.installFirmwareScreen = true;
      } else {
        this.defaultSetupScreen = true;
      }
    }

    if (this.device.features['initialized']) {
      if (this.device.features['pin_protection']) {
        this.router.navigate(
          ['portfolio'],
          { queryParams: this.route.snapshot.queryParams }).then(() => this.vault.unsubscribeAll());
      } else {
        this.hideAllScreens();
        this.newWalletSetupNameScreen = true;
      }
    }
  }

  onWordSelection(wordSelection: string) {
    this.seedWord = wordSelection;
  }

  convertButtonRequestToSeedWord(request: number) {
    const seedWord = request % 24 || 24;
    let seedWordLabel;

    switch (seedWord) {
      case 1:
      case 21:
        seedWordLabel = `${seedWord}st`;
        break;
      case 2:
      case 22:
        seedWordLabel = `${seedWord}nd`;
        break;
      case 3:
      case 23:
        seedWordLabel = `${seedWord}rd`;
        break;
      default:
        seedWordLabel = `${seedWord}th`;
    }

    return seedWordLabel;
  }

  addPinDigit(digit: string) {
    if (!this.pin) this.pin = '';
    
    this.pin += digit;
  }

  deletePinDigit() {
    this.pin = this.pin.slice(0, -1);
  }

  hideAllScreens() {
    this.installFirmwareScreen = false;
    this.defaultSetupScreen = false;
    this.newWalletSetupTermsOfServiceScreen = false;
    this.newWalletSetupPassphraseScreen = false;
    this.newWalletSetupPinScreen = false;
    this.newWalletSetupSeedScreen = false;
    this.newWalletSetupNameScreen = false;
    this.newWalletReconnectNotice = false;
    this.walletRecoveryTermsOfServiceScreen = false;
    this.walletRecoveryPassphraseScreen = false;
    this.walletRecoverySeedScreen = false;
    this.walletRecoverySeedEntryScreen = false;
    this.walletRecoveryPinScreen = false;
    this.walletRecoveryNameScreen = false;
  }

  installFirmware() {
    this.vault.fetchFirmware().then(bytes => {
      console.log('Firmware size (bytes):', bytes.byteLength);

      this.vault.updateFirmware({binary: Buffer.from(bytes)}).then(results => {
        console.log(results);
      });
    }).catch(err => {
      console.log('ERROR ERROR ERROR');
    });
  }

  async setupNewWallet(step: Number) {
    this.hideAllScreens();

    switch(step) {
      case 0:
        this.newWalletSetupTermsOfServiceScreen = true;
        break;
      case 1:
        this.newWalletSetupPassphraseScreen = true;
        break;
      case 2:
        if (this.passphrase.value.length < 10 || this.passphrase.value === this.passphrase.value.toLowerCase()) {
          this.passphraseError = true;
          this.setupNewWallet(1);
        } else {
          this.vault.setPassphrase(this.passphrase.value);
          window.localStorage.setItem('pp', this.passphrase.value);
          this.newWalletSetupSeedScreen = true;
        }
        break;
      case 3:
        this.currentButtonRequest = 0;
        let results = await this.vault.resetDevice({passphrase_protection: true, useEmptyPassphrase: false, skipFinalReload: false});
        if (!results.success) {
          this.setupNewWallet(2);
        }
        this.newWalletSetupNameScreen = true;
        break;
      case 4:
        this.vault.applySettings({label: String(this.deviceName.value), use_passphrase: true, useEmptyPassphrase: false, skipFinalReload: false}).then(() => {
          this.setupNewWallet(5);
        });
        break;
      case 5:
        this.newWalletSetupPinScreen = true;
        break;
      case 6:
        this.vault.changePin({remove: false}).then((results) => {
          if (!results.success) {
            this.setupNewWallet(4);
          } else {
            this.deviceName.setValue('');
            window.location.reload();
            // Note: We would normally router.navigate to the portfolio but because of how we are handling passphrases, we need to do a full reload and let the app init
          }
        });
        break;
      default:
        this.defaultSetupScreen = true;
        break;
    }    
  }

  async recoverWallet(step: Number) {
    this.hideAllScreens();

    switch(step) {
      case 0:
        this.walletRecoveryTermsOfServiceScreen = true;
        break;
      case 1:
        this.walletRecoveryPassphraseScreen = true;
        break;
      case 2:
        if (this.passphrase.value.length < 10 || this.passphrase.value === this.passphrase.value.toLowerCase()) {
          this.passphraseError = true;
          this.recoverWallet(1);
        } else {
          this.vault.setPassphrase(this.passphrase.value);
          window.localStorage.setItem('pp', this.passphrase.value);
          this.walletRecoverySeedScreen = true;
        }
        break;
      case 3:
        this.currentButtonRequest = 0;
        this.vault.recoverDevice({word_count: 24, keepSession: true}).then(results => {
          if (!results.success) {
            this.recoverWallet(1);
          } else {
            this.walletRecoveryNameScreen = true;
            window.location.reload();
          }
        });
        break;
      default:
        this.defaultSetupScreen = true;
        break;
    }
  }

  sendPin() {
    this.vault.uiResponse({type: UI.RECEIVE_PIN, payload: this.pin});
    this.modal.dismissAll();
    this.pin = '';
    this.pinReEntry = !this.pinReEntry;
  }

  sendWord() {
    this.vault.uiResponse({type: UI.RECEIVE_WORD, payload: this.seedWord});
    this.modal.dismissAll();
    this.seedWord = '';
  }
}
