import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'portfolio-overview',
  templateUrl: './portfolio-overview.component.html',
  styleUrls: ['./portfolio-overview.component.css'],

})
export class PortfolioOverviewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
}